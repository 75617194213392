import request from '@/utils/request'

export function getForms (param) {
  return request({
    url: 'trade/approve/form/',
    method: 'get',
    params: param
  })
}

// 更新表单
export function updateForm (param, id) {
  return request({
    url: 'trade/approve/form/' + id + '/',
    method: 'patch',
    data: param
  })
}

export function createForm (param) {
  return request({
    url: 'trade/approve/form/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: param
  })
}

export function createProcessResult (param) {
  return request({
    url: 'trade/approve/process_result/create/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: param
  })
}

// 查询表单详情
export function getFormDetail (id) {
  return request({
    url: 'trade/approve/form/' + id + '/',
    method: 'get'
  })
}

// 更新表单详情
export function updateFormDetail (param, id) {
  return request({
    url: 'trade/approve/form/' + id + '/',
    method: 'put',
    data: param
  })
}

// 查询流程详情
export function getProcessInstanceInfo (id) {
  return request({
    url: 'trade/approve/todo/' + id + '/',
    method: 'GET'
  })
}

export function getApproveToDoList (param) {
  return request({
    url: 'trade/approve/todo/',
    method: 'get',
    params: param
  })
}

export function doApproveToDo (id, data) {
  return request({
    url: 'trade/approve/todo/' + id + '/',
    method: 'put',
    data: data
  })
}

export function getApproveToDoAllList (param) {
  return request({
    url: 'trade/approve/todo/all/',
    method: 'get',
    params: param
  })
}

export function getApproveToDoDetail (param) {
  return request({
    url: 'trade/approve/todo/detail/',
    method: 'get',
    params: param
  })
}
