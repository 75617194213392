import request from '@/utils/request'

const collectionApi = {
  trade_collection_list: '/trade/collection/',
  trade_collection_create: '/trade/collection/',
  trade_collection_update: '/trade/collection/',
  trade_collection_delete: '/trade/collection/',
  trade_collection_archived: '/trade/collection_archived/'
}

/**
 * 列表
 */
export function collection_list (parameter) {
  return request({
    url: collectionApi.trade_collection_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function collection_create (parameter) {
  return request({
    url: collectionApi.trade_collection_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function collection_update (parameter, corporation_id) {
  return request({
    url: collectionApi.trade_collection_update + corporation_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function collection_delete (corporation_id) {
  return request({
    url: collectionApi.trade_collection_delete + corporation_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function collection_archived (parameter) {
  return request({
    url: collectionApi.trade_collection_archived,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
