import request from '@/utils/request'

const tradeProjectClientApi = {
  trade_project_client_list: '/trade/project_client/',
  trade_project_client_create: '/trade/project_client/',
  trade_project_client_update: '/trade/project_client/',
  trade_project_client_delete: '/trade/project_client/'
}

/**
 * 列表
 */
export function project_client_list (parameter) {
  return request({
    url: tradeProjectClientApi.trade_project_client_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function project_client_create (parameter) {
  return request({
    url: tradeProjectClientApi.trade_project_client_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function project_client_update (parameter, project_client_id) {
  return request({
    url: tradeProjectClientApi.trade_project_client_update + project_client_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function project_client_delete (project_client_id) {
  return request({
    url: tradeProjectClientApi.trade_project_client_delete + project_client_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
