import request from '@/utils/request'

const tradeClientApi = {
  trade_client_list: '/trade/client/',
  trade_client_create: '/trade/client/',
  trade_client_update: '/trade/client/',
  trade_client_delete: '/trade/client/'
}

/**
 * 列表
 */
export function client_list (parameter) {
  return request({
    url: tradeClientApi.trade_client_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function client_create (parameter) {
  return request({
    url: tradeClientApi.trade_client_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function client_update (parameter, client_id) {
  return request({
    url: tradeClientApi.trade_client_update + client_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function client_delete (client_id) {
  return request({
    url: tradeClientApi.trade_client_delete + client_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
