<template>
  <a-modal
    title="新建收款"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="this.ok"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="项目" v-if="false">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'project_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入项目"
                :default-active-first-option="false"
                @search="this.projectSearch"
                @change="handleProjectChange"
              >
                <a-select-option v-for="d in projectList" :key="d.id" :tt="d.name">
                  {{ d.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="客户">
              <a-select
                option-label-prop="tt"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'client_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                placeholder="请输入客户"
                :default-active-first-option="false"
                @search="this.clientSearch"
                @change="handleClientChange"
              >
                <a-select-option v-for="d in clientList" :key="d.client_id" :tt="d.client">
                  {{ d.client }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="货币" >
              <a-select
                v-decorator="[
                  'currency',
                  {
                    rules: [
                      { required: true, message: '请选择' }
                    ],
                  },
                ]"
                placeholder="选择"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.currency">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="付款金额">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['amount', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="预估人民币价格">
              <a-input-number :precision="2" style="width: 50%" v-decorator="['amount_cny', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="支付方式" >
              <a-select
                v-decorator="[
                  'method',
                  {
                    rules: [
                      { required: true, message: '请选择' }
                    ],
                  },
                ]"
                placeholder="选择"
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.method">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="支付时间">
              <a-date-picker
                style="width:100%"
                v-decorator="['pay_time', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
                format="YYYY-MM-DD HH:mm:ss"
                :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                placeholder="请选择时间"
                @ok="payTimeOK"
              />
            </a-form-item>
            <a-form-item label="已存档" v-if="false">
              <a-switch v-decorator="['is_archived', { initialValue: false, valuePropName: 'checked' }]" />
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea :rows="4" v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="附件">
              <a-button icon="plus" @click="handleAddAttachment">新建</a-button>
              <a-table
                bordered
                :data-source="otherData.attachment"
                :columns="attachmentColumns"
                v-if="this.otherData.attachment.length > 0"
                :pagination="false"
                key="key">
                <template slot="action" slot-scope="text, record">
                  <a-popconfirm
                    v-if="otherData.attachment.length"
                    title="确定删除?"
                    @confirm="() => onAttachmentDelete(record.key)"
                  >
                    <a href="javascript:;">删除</a>
                  </a-popconfirm>
                </template>
              </a-table>
            </a-form-item>
            <a-form-item label="开启审批">
              <a-switch v-decorator="['is_approval_needed', { initialValue: true, valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <attachment-create-form
        ref="attachmentCreateForm"
        @cancel="handleAddCancel"
        @ok="handleOk"
        :record="mdl"
        :visible="visibleFrom"
        v-if="visibleFrom"
        :loading="confirmLoadingFrom"
        :model="mdl" />
    </a-spin>
  </a-modal>
</template>

<script>
import moment from 'moment'
import pick from 'lodash.pick'
import { project_list } from '@/api/trade_project'
import debounce from 'lodash/debounce'
import AttachmentCreateForm from '../attachment/modules/CreateForm'
import { project_client_list } from '@/api/trade_project_client'

// 表单字段
const fields = ['id', 'name', 'contact', 'phone', 'mail']
export default {
  components: {
    AttachmentCreateForm
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    this.projectSearch = debounce(this.handleProjectSearch, 800)
    this.clientSearch = debounce(this.handleClientSearch, 800)
    return {
      form: this.$form.createForm(this),
      projectList: [],
      clientList: [],
      currency: this.$Dictionaries.currency,
      method: this.$Dictionaries.trade_payment_method,
      visibleFrom: false,
      confirmLoadingFrom: false,
      mdl: {},
      otherData: {
        attachment: []
      },
      attachmentColumns: [
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '附件',
          dataIndex: 'url'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
    this.handleProjectSearch(undefined)
    this.handleClientSearch(undefined)
  },
  methods: {
    moment,
    handleProjectSearch (value) {
      project_list({ name: value }).then((res) => {
        const result = res.data.entries || []
        this.projectList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleProjectChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleProjectSearch(value)
      }
    },
    handleClientSearch (value) {
      project_client_list({ name: value, project_id: this.$route.params.project_id }).then((res) => {
        const result = res.data.entries || []
        this.clientList = !value ? (result.length > 5 ? result.slice(0, 5) : result) : result
      })
    },
    handleClientChange (value) {
      console.log(value)
      if (value === undefined) {
        this.handleClientSearch(value)
      }
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    payTimeOK (value) {
      console.log(value.format('YYYY-MM-DD HH:mm:ss'))
    },
    handleAddAttachment () {
      this.visibleFrom = true
    },
    onAttachmentDelete (key) {
      const dataSource = [...this.otherData.attachment]
      this.otherData.attachment = dataSource.filter(item => item.key !== key)
    },
    handleAddCancel () {
      this.visibleFrom = false
      const form = this.$refs.attachmentCreateForm.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk (date) {
      const form = this.$refs.attachmentCreateForm.form
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values, date)
          // 新增
          values.url = values.url.fileList[0].url
          const current_date = new Date()
          values.key = current_date.getHours() + '' + current_date.getMinutes() + '' + current_date.getSeconds()
          this.otherData.attachment.push(values)
          this.visibleFrom = false
          form.resetFields()
        }
      })
    },
    ok () {
      if (this.otherData.attachment) {
        this.otherData.attachment = this.otherData.attachment.map(item => {
          item.key = undefined
          return item
        })
      }
      this.$emit('ok', this.otherData)
    }
  }
}
</script>
